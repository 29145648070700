<template>
        <footer class="footer has-style2 bg-grad2 bg-pattern">
        <div class="footer-body bg-transparent">
            <div class="container">
                <div class="row ">
                    <div class="col-lg-6">
                        <div class="footer-desc">
                            <div class="logo">
                                <img :src="`${rootPath}assets/images/MindsetGx/MindsetGx-Completo.png`" alt="MindsetGx-Completo.png">
                            </div>
                            <p>
                                <!-- Empieza a cambiar tu mente -->
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-2 col-6">
                        <h6 class="list-title">Redes sociales</h6>
                        <ul class="list-items">
                            <li> <a href="https://www.facebook.com/mindsetgx/">Facebook</a> </li>
                            <li> <a href="https://www.instagram.com/mindsetgx/">Instagram</a> </li>
                            <li> <a href="https://www.linkedin.com/company/mindsetgx/">LinkedIn</a> </li>
                        </ul>
                    </div>
                    <div class="col-lg-2 col-6">
                        <h6 class="list-title">Links útiles</h6>
                        <ul class="list-items">
                            <li> <router-link class="" to="/Servicios">Servicios</router-link> </li>
                            <li> <router-link class="" to="/Nosotros">Nosotros</router-link></li>
                            <li> <router-link class="" to="/Contactanos">Contáctanos</router-link> </li>
                        </ul>
                    </div>
                    <div class="col-lg-2">
                        <h6 class="list-title">Legal</h6>
                        <ul class="list-items">
                            <li> <a href="#">Terms</a> </li>
                            <li> <a href="#">Privacy </a> </li>
                            <li> <a href="#">Cookies</a> </li>
                        </ul>
                    </div>
                </div>
                <p class="copyright text-center text-copyright"> MindsetGx | Todos los derechos reservados 2022</p>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
  name: 'footer', 
  data(){
      return{
          rootPath: process.env.BASE_URL
      }
  }
}
</script>