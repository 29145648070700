<template>
    <div>

        <div class="container aos-init aos-animate">
            <section class="section is-sm pb-0">
                <div class=" section section-companies has-style1 is-dark">
                    <div class="row min-30 flex center">
                        <div class="col-lg-3 col-md-3  col-6">
                            <div class="company-item">
                                <img :src="`${rootPath}assets/images/logos/google.svg`" alt="google.svg">
                            </div>

                        </div>
                        <div class="col-lg-3 col-md-3 col-6">
                            <div class="company-item">
                                <img :src="`${rootPath}assets/images/logos/github.svg`" alt="github.svg">
                            </div>

                        </div>

                        <div class="col-lg-3 col-md-3 col-6">
                            <div class="company-item">
                                <img :src="`${rootPath}assets/images/logos/envato.svg`" alt="envato.svg">
                            </div>

                        </div>

                        <div class="col-lg-3 col-md-3 col-6">
                            <div class="company-item">
                                <img :src="`${rootPath}assets/images/logos/slack.svg`" alt="slack.svg">
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <!-- =========================== nuestro fuerte -->
        <section class="section is-sm section-feautures">
            <div class="container">
                <div class="section-head">
                    <h2 class="section-title is-center"> Construímos experiencias digitales duraderas </h2>
                </div>
                <div class="boxes">
                    <div class="row min-30 flex center">
                        <div class="col-lg-4 col-md-6">
                            <div class="box has-grad1 ">
                                <div class="box-icon">
                                    <ion-icon name="logo-buffer"></ion-icon>
                                </div>
                                <h3 class="box-title">Análisis</h3>
                                <p class="box-desc">
                                    <!-- Estámos capacitados para realizar inmersiones en tus procesos de 
                                    producción para conocer tu negocio con el objetivo potenciar tus fortalezas. -->
                                    El análisis de negocio y procesos es escencial para identificar oportunidades, con el objetivo de mejorar la operatividad a nivel empresarial.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="box has-shadow has-blue-text">
                                <div class="box-icon">
                                    <ion-icon name="logo-buffer" style="color: #19aebe;"></ion-icon>
                                </div>
                                <h3 class="box-title">Desarrollo</h3>
                                <p class="box-desc">
                                    Nuestro compromiso es crear software que facilite la ejecución de los procesos, 
                                    garantizando la entrega de un producto final robusto, sostenible y escalable.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="box has-grad1 ">
                                <div class="box-icon">
                                    <ion-icon name="medal"></ion-icon>
                                </div>
                                <h3 class="box-title">Soporte continuo</h3>
                                <p class="box-desc">
                                    Contamos con un portal de soporte técnico para nuestros clientes. 
                                    Monitoreamos las solicitudes de soporte técnico en tiempo real, asegurando soluciones efectivas.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        <!-- =========================== Obra maestra-->
        <section class="section is-sm section-about section-counter">
            <div class="container">
                <div class="row flex vcenter">
                    <div class="col-lg-6">
                        <div class="section-head">
                            <!-- <h5 class="section-subtitle text-green"> Qué hacemos mejor </h5> -->
                            <h2 class="section-title">
                                Permítenos Ser Los Artistas De Tu Obra Maestra
                            </h2>
                            <p class="section-desc">
                                Somos un equipo multidiciplinario preparado y listo para desarrollar páginas web, aplicaciones
                                o cualquier clase de software que funcione como herramienta tecnológica con la finalidad de apoyar tu organización. 
                                Nuestro trabajo orientado a objetivos prioriza aspectos como la visibilidad, reducción de tiempos en la ejecución de tareas y el incremento 
                                de la productividad.
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <img class="about-img" :src="`${rootPath}assets/images/others/grad1.svg`" alt="grad1.svg">
                        <img class="section-shape1" :src="`${rootPath}assets/images/others/shape-illustration-grad.svg`" alt="shape-illustration-grad.svg">
                    </div>

                </div>
                <div class="row counters flex center min-20">
                    <!-- ==== item -->
                    <div class="col-lg-3 col-md-4 col-6">
                        <div class="counter-item">
                            <h3 class="counter-number text-grad"> + <span class="counter"> 8 </span> </h3>
                            <p class="counter-name"> Clientes </p>
                        </div>
                    </div>
                    <!-- ==== item -->
                    <div class="col-lg-3  col-md-4 col-6">
                        <div class="counter-item">
                            <h3 class="counter-number text-grad"> + <span class="counter"> 3 </span></h3>
                            <p class="counter-name"> Colaboradores </p>
                        </div>
                    </div>
                    <!-- ==== item -->
                    <div class="col-lg-3  col-md-4 col-6">
                        <div class="counter-item">
                            <h3 class="counter-number text-grad "> + <span class="counter"> 17 </span> </h3>
                            <p class="counter-name"> Proyectos </p>
                        </div>
                    </div>
                    <!-- ==== item -->
                    <div class="col-lg-3  col-md-4 col-6">
                        <div class="counter-item">
                            <h3 class="counter-number text-grad"> + <span class="counter"> 2 </span></h3>
                            <p class="counter-name"> Socios estratégicos </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- =========================== section feautures-->
        <section class="section is-sm section-grey-half section-counter" style="padding-top: 30px !important; padding-bottom: 120px !important;">
            <div class="container">
                <div class="lines">
                    <img :src="`${rootPath}assets/images/others/lines.svg`" alt="lines.svg">
                </div>
                <div class="section-head flex between vcenter wrap">
                    <h2 class="section-title" style="text-transform: none !important;">
                        Nuestra mentalidad no es ser los primeros. Sino, ser los mejores.
                    </h2>
                    <!-- <a class="btn btn-green btn-round text-white md-hidden"> Vamos allá!</a> -->
                </div>
            </div>
        </section>

        <!-- =========================== Nuestras especialidades-->
        <section class="section is-sm section-blog" style="padding-top: 0px !important;">
            <div class="container">
                <div class="section-head flex between vcenter">
                    <h2 class="section-title">Nuestras especialidades<span class="text-blue">. </span></h2>
                    <!-- <a href="" class="btn btn-opacity btn-blue mt-sm-30 sm-hidden "> see more </a> -->
                </div>
                <div class="work-boxes blog-boxes-slick">
                    <div class="">
                        <div class="post-wrap mt-0">
                            <a href="#">
                                <div class="post-img">
                                    <img :src="`${rootPath}assets/images/Team/Analysis.svg`" alt="Analysis.svg">
                                </div>
                            </a>
                            <div class="post-content mb-0">
                                <div class="post-meta">
                                    <div class="post-tag"> <span class="tag-item">Análisis</span> </div>
                                </div>
                                <h3 class="post-title">
                                    <a href="#" class="hover-blue">Análisis de negocio</a>
                                </h3>
                                <p class="post-description">
                                    Es primordial estudiar los procesos de tu empresa para identificar 
                                    los distintos puntos de mejora.
                                </p>
                                <!-- <span class="post-author">Por 
                                    <a href="#" class="text-blue" rel="author">admin</a>
                                </span>
                                <span class="post-date"> 18 de agosto de 2022 </span> -->
                            </div>
                        </div>
                    </div>
                    <div class="">
                        <div class="post-wrap mt-0">
                            <a href="#">
                                <div class="post-img">
                                    <img :src="`${rootPath}assets/images/Team/DevOps.svg`" alt="DevOps.svg">
                                </div>
                            </a>
                            <div class="post-content mb-0">
                                <div class="post-meta">
                                    <div class="post-tag"> <span class="tag-item">DevOps</span> </div>
                                </div>
                                <h3 class="post-title">
                                    <a href="#" class="hover-blue">Arquitectura de software</a>
                                </h3>
                                <p class="post-description">
                                    Un pilar fundamental de un software por excelencia, es la implementación 
                                    de una arquitectura de calidad que permita escalamientos verticales u 
                                    horizontales según tus recursos y necesidades.
                                </p>
                                <!-- <span class="post-author">Por 
                                    <a href="#" class="text-blue" rel="author">admin</a>
                                </span>
                                <span class="post-date"> 18 de agosto de 2022 </span> -->
                            </div>
                        </div>
                    </div>
                    <div class="">
                        <div class="post-wrap mt-0">
                            <a href="#">
                                <div class="post-img">
                                    <img :src="`${rootPath}assets/images/Team/Backend2.svg`" alt="Backend2.svg">
                                </div>
                            </a>
                            <div class="post-content mb-0">
                                <div class="post-meta">
                                    <div class="post-tag"> <span class="tag-item">Desarrollo</span> </div>
                                </div>
                                <h3 class="post-title">
                                    <a href="#" class="hover-blue">Backend y patrones de diseño</a>
                                </h3>
                                <p class="post-description">
                                    Un buen desarrollador de software cuenta con sus confiables tazas de café e 
                                    increíbles patrones de diseño : ). 
                                </p>
                                <!-- <span class="post-author">Por 
                                    <a href="#" class="text-blue" rel="author">admin</a>
                                </span>
                                <span class="post-date"> 18 de agosto de 2022 </span> -->
                            </div>
                        </div>
                    </div>
                    <div class="">
                        <div class="post-wrap mt-0">
                            <a href="#">
                                <div class="post-img">
                                    <img :src="`${rootPath}assets/images/Team/UIDesign.svg`" alt="UIDesign.svg">
                                </div>
                            </a>
                            <div class="post-content mb-0">
                                <div class="post-meta">
                                    <div class="post-tag"> <span class="tag-item">UI/UX</span> </div>
                                </div>
                                <h3 class="post-title">
                                    <a href="#" class="hover-blue">Interfaz y experiencia de usuario</a>
                                </h3>
                                <p class="post-description">
                                    Sin importar quien, está claro que cuanto más intuitiva y 
                                    agradable sea la interfaz, la experiencia será mucho mejor.
                                </p>
                                 <!-- <span class="post-author">Por 
                                    <a href="#" class="text-blue" rel="author">admin</a>
                                </span>
                                <span class="post-date"> 18 de agosto de 2022 </span> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>    

        <!-- =========================== section Testimonials-->
        <section class="section is-sm section-testimonial overflow-hidden" style="padding-top: 10px !important;">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="section-head mb-lg-0">
                            <h5 class="section-subtitle text-green"> Testimonios </h5>
                            <h2 class="section-title">
                                Ayudamos a emprendedores como tú diariamente
                                <!-- <span class="text-green">. </span> -->
                            </h2>
                            <h3 class="display-4 mb-2 text-green mb-0">4.5
                            </h3>
                            <p class="section-desc max-30 mt-1 mb-1">
                                Conoce los comentarios de uno de nuestros clientes. 
                            </p>
                            <img class="stars" :src="`${rootPath}assets/images/others/stars.svg`" alt="stars.svg">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="client-wrap is-white">
                            <div class="client-img">
                                <img :src="`${rootPath}assets/images/logos/TalentoCorporativo_Logo_Icon.png`" alt="TalentoCorporativo_Logo_Icon.png">
                            </div>
                            <p class="client-quote"> Mi experiencia con Mindsetgx ha sido muy productiva, siento que dan un respaldo y confibilidad a traves de sus servicios, siempre buscando las mejores soluciones y la innovacion. Estos 2 años me he sentido satisfecha de los servicios que requerí, gracias por su profesionalismo.
                            </p>
                            <div class="flex">
                                <strong class=" client-name ">Nancy Valenzuela</strong>
                                <p class="client-position">Gerenta General</p>
                            </div>
                        </div>
                        <img class="section-shape2" :src="`${rootPath}assets/images/others/testimonials-grad.svg`" alt="testimonials-grad.svg">
                    </div>
                </div>
            </div>
        </section>

    </div>
</template>

<script>
// import { useStore, computed } from 'vuex'
import { useStore } from 'vuex'
export default {
    name: 'inicio',
    setup() {
        // const store = new useStore();
        // // Obtener un valor computado desde el store
        // let counter = computed(() => store.state.count);
        // console.log(counter)
        // return { counter }
    },
    mounted(){
        const store = new useStore();
        store.commit('showContentHeader', 'Inicio');
    },
    data(){
        return{
            rootPath: process.env.BASE_URL
        }
    }    
}
</script>

