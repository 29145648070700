<template>
    <header class="header has-style2">
        <!-- =========================== navbar-->
        <nav class="navbar is-dark">
            <div class="container">
                <div class="flex">
                    <router-link to="/" class="navbar-brand flex vcenter" onclick="_closeMenuInicio()">
                        <h1 class="HeaderGX">MINDSET GX</h1>
                    </router-link>
                    <ul class="navbar-menu">
                        <li> 
                            <router-link class="btnLinkInicio" onclick="initializeCarrousel()" to="/">Inicio</router-link>
                        </li>
                        <li class="menu-item-has-children">
                            <router-link class="" to="/Servicios">Servicios</router-link>
                        </li>
                        <li class="menu-item-has-children">
                            <router-link class="" to="/Nosotros">Nosotros</router-link>
                        </li>
                        <li> 
                            <router-link class="" to="/Contactanos">Contáctanos</router-link>
                        </li>
                    </ul>
                </div>
                <div class="level-right">
                    <div class="mobile-menu" id="movil-menu">
                        <!-- your list menu in mobile here -->
                        <ul>
                            <li> 
                                <router-link class="btnLinkInicio" onclick="_closeMenuInicio()" to="/"><span class="text-grad">Inicio</span></router-link>
                            </li>
                            <li class="menu-item-has-children">
                                <router-link class="" onclick="_closeMenu()" to="/Servicios"><span class="text-grad">Servicios</span></router-link>
                            </li>
                            <li class="menu-item-has-children">
                                <router-link class="" onclick="_closeMenu()" to="/Nosotros"><span class="text-grad">Nosotros</span></router-link>
                            </li>
                            <li> 
                                <router-link class="" onclick="_closeMenu()" to="/Contactanos"><span class="text-grad">Contáctanos</span></router-link>
                            </li>
                        </ul>
                    </div>
                    <div class="flex">
                        <div class="menu-toggle-icon">
                            <div class="menu-toggle">
                                <div class="menu">
                                    <input type="checkbox">
                                    <div class="line-menu"></div>
                                    <div class="line-menu"></div>
                                    <div class="line-menu"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>

        <div class="container" v-if="_contentHeader == 'Inicio'">
            <div class="header-wrap">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <h2 class="header-title">
                            <!-- Empieza cambiando tu mente -->
                            El desarrollo inicia al cambiar tu mente
                        </h2>
                        <p class="header-desc">
                            Un equipo de mentes brillantes con el único objetivo de llevar soluciones tecnológicas hasta tu negocio.
                            Conéctate con otras empresas dentro de nuestra red, amplía tus contactos y posibilidades.
                        </p>
                        <!-- <a href="#" class="btn btn-green btn-round">
                            Comencemos  <ion-icon name="arrow-forward"></ion-icon>
                        </a> -->
                        <router-link class="btn btn-green btn-round" to="/Contactanos">
                            Comencemos  <ion-icon name="arrow-forward"></ion-icon>
                        </router-link>
                    </div>
                    <div class="col-lg-6">
                        <div class="header-img">
                            <img :src="`${rootPath}assets/images/MindsetGx/SoloLogo.png`" alt="SoloLogo.png">
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <section class="section sm section-blog2 mt-3" v-if="_contentHeader == 'Nosotros'" style="padding-bottom:80px;">
            <div class="container">
                <img class="img-post" :src="`${rootPath}assets/images/MindsetGx/Nosotros.jpg`" alt="Nosotros.jpg">
                <div class="content-post">
                    <div class="row">
                        <div class="col-md-4">
                            <p class="color-primary">
                                <strong class="mindsetforecolor">Misión</strong>
                            </p>
                            <p>
                                Nuestra misión es reducir progresivamente el desface tecnológico del país. Facilitando recursos tecnológicos modernos, principalmente a los grupos y sectores menos favorecidos como MIPYMES, instituciones públicas y proyectos sociales.
                            </p>
                        </div>
                        <div class="col-md-4">
                            <p class="color-primary">
                                <strong class="mindsetforecolor">Visión</strong>
                            </p>
                            <!-- Convertirnos en una potencia enfocada a servicios de desarrollo de software -->
                            <p>Ser la alternativa más confiable, accesible y efectiva de nuestro ámbito laboral. Posicionándonos cómo los máximos referentes del mercado a nivel nacional. </p>
                        </div>
                        <div class="col-md-4">
                            <p class=" color-primary">
                                <strong class="mindsetforecolor">Valores</strong>
                            </p>
                            <p>
                                Competitividad  <br>
                                Compromiso  <br>
                                Excelencia  <br>
                                Honestidad  <br>
                                Humildad    <br>
                                Orientación al cliente  <br>
                                Pasión <br>
                                Puntualidad <br>
                                Responsabilidad Social  <br>
                                Trabajo en equipo <br>
                            </p>
                        </div>
                    </div>

                    <br /><br />
                    <p class="color-primary">
                        <strong class="mindsetforecolor">Historia.</strong>
                    </p>
                    <p>
                        En el año 2018 surge una idea en los salones del Instituto Gubernamental 1 de Mayo. 
                        Una idea impulsada por el deseo de mejorar las condiciones de atención en múltiples servicios, 
                        el objetivo potenciar las áreas menos desarrolladas a través de soluciones tecnológicas, 
                        el reto de superar nuestros límites y el sin fin de adversidades que enfrentamos diariamente.
                        <br><br>
                        En el año 2019 MindsetGx emprende su travesía como un pequeño equipo de profesionales Outsourcing, 
                        capaces y dispuestos a demostrar su potencial. Luego de muchas semanas y algunas tazas de café, 
                        recibimos invitación por parte de FUNADEH GENESIS al evento de emprendimiento (Programatón 2018). En donde presentamos 
                        una de nuestras primeras obras (Alpha). Un sistema orientado al sector salud, nuestro proyecto tenía como único 
                        objetivo depurar la ineficiencia en los procesos de atención y disminuir la exposición a distintos factores de riesgo 
                        a los que se veían sometidos los pacientes al tratar de recibir atención médica en hospitales públicos. Esto se lograría 
                        mediante la virtualización de múltiples procesos, fomentando la productividad del personal médico y reduciendo 
                        el tráfico de pacientes en las salas de espera tanto como en ventanillas de inscripción previa para una cita. 
                        Alpha fue seleccionado como segundo lugar del evento. La historia de una derrota con sabor a victoria.
                        <br> <br>
                        El año 2020 fue muy difícil para todos, el inicio de la pandemia trajo consigo muchas tragedias y 
                        problemas que desestabilizaron la economía. Sin embargo, el confinamiento obligó a las personas a hacer 
                        uso de servicios en línea potenciando el desarrollo tecnológico en distintos comercios y servicios.
                        Esta situación incrementó la demanda en el campo del desarrollo de software, convirtiéndose en una
                        increíble oportunidad para nuestro equipo. En los meses siguientes concretamos múltiples colaboraciones 
                        con distintos comercios y corporaciones, haciendo crecer nuestro nombre mientras demostrábamos nuestra capacidad. 
                        <br>
                    </p>
                    <p>
                        Hasta el día de hoy, nunca permitimos que los problemas impusieran los límites de nuestro ingenio. Hicimos de los problemas 
                        algo increíble. Con buena mentalidad los problemas se perciben cómo una necesidad, y en donde hay necesidad, existe oportunidad.
                        <label style="color:#10e6fe; text-decoration:underline;">#MindsetGX</label>
                    </p>

                    <br /><br /><br />
                </div>
                <!-- =============================== leave coment -->
                <div class="container mb-6">
                </div>
            </div>
        </section>

        <section class="section is-sm" v-if="_contentHeader == 'Servicios'" style="padding-bottom:130px;">
            <div class="container">
                <h2 class="section-title aos-init aos-animate" data-aos="fade-up" data-aos-delay="500" style="font-size: 40px; padding: 10px;"> Nuestros servicios</h2>
                <div class="boxes">
                    <div class="row min-30 flex center">

                        <div class="col-lg-4 col-md-6">
                            <div class="box has-shadow has-left-icon">
                                <div class="box-particles2">
                                    <img :src="`${rootPath}assets/images/others/box-particle-2.svg`" alt="box-particle-2.svg">
                                </div>
                                <div class="row">
                                    <div class="col-auto">
                                        <div class="box-icon">
                                            <ion-icon name="logo-html5" role="img" class="md hydrated"></ion-icon>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <h3 class="box-title"> Página web </h3>
                                        <p class="box-desc">Una página web es indispensable para que incrementes tu visibilidad.
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="box  has-shadow has-left-icon">
                                <div class="box-particles2">
                                    <img :src="`${rootPath}assets/images/others/box-particle-2.svg`" alt="box-particle-2.svg">
                                </div>
                                <div class="row">
                                    <div class="col-auto">
                                        <div class="box-icon">
                                            <ion-icon name="cart" role="img" class="md hydrated"></ion-icon>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <h3 class="box-title"> Tienda online </h3>
                                        <p class="box-desc">¿Necesitas vender en línea? 
                                            No te preocupes, tenemos increíbles soluciones para tí.
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="box has-shadow has-left-icon">
                                <div class="box-particles2">
                                    <img :src="`${rootPath}assets/images/others/box-particle-2.svg`" alt="box-particle-2.svg">
                                </div>
                                <div class="row">
                                    <div class="col-auto">
                                        <div class="box-icon">
                                            <ion-icon name="phone-portrait" role="img" class="md hydrated" aria-label="logo dropbox"></ion-icon>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <h3 class="box-title"> Aplicaciones </h3>
                                        <p class="box-desc">
                                            Accesabilidad y control total de tu negocio en la palma de tu mano.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="box has-shadow has-left-icon">
                                <div class="box-particles2">
                                    <img :src="`${rootPath}assets/images/others/box-particle-2.svg`" alt="box-particle-2.svg">
                                </div>
                                <div class="row">
                                    <div class="col-auto">
                                        <div class="box-icon">
                                            <ion-icon name="laptop" role="img" class="md hydrated" aria-label="logo dropbox"></ion-icon>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <h3 class="box-title"> Sistemas empresariales </h3>
                                        <p class="box-desc">
                                            El software es indispensable para asistir y mejorar los procesos de tu empresa.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="box has-shadow has-left-icon">
                                <div class="box-particles2">
                                    <img :src="`${rootPath}assets/images/others/box-particle-2.svg`" alt="box-particle-2.svg">
                                </div>
                                <div class="row">
                                    <div class="col-auto">
                                        <div class="box-icon">
                                            <ion-icon name="mail-unread" role="img" class="md hydrated" aria-label="logo dropbox"></ion-icon>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <h3 class="box-title"> Correos corporativos </h3>
                                        <p class="box-desc">Utiliza correos con dominio personalizado y comunícate con tus clientes.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="box has-shadow has-left-icon">
                                <div class="box-particles2">
                                    <img :src="`${rootPath}assets/images/others/box-particle-2.svg`" alt="box-particle-2.svg">
                                </div>
                                <div class="row">
                                    <div class="col-auto">
                                        <div class="box-icon">
                                            <ion-icon name="people" role="img" class="md hydrated" ></ion-icon>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <h3 class="box-title"> Consultorías y outsourcing</h3>
                                        <p class="box-desc">Nuestro objetivo no es convertirte en el primero. Sino, en el mejor.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>

        <section class="section is-sm section-contact" v-if="_contentHeader == 'Contactanos'" style="padding-bottom:130px;">
            <div class="row">
                <img class="section-particle top-0" :src="`${rootPath}assets/images/others/particle.svg`" alt="particle.svg">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class=" items-contact">
                                <div class="section-head">
                                    <h2 class="section-title "> Contáctanos </h2>
                                </div>
                                <div class="col-lg-12">
                                    <div class="contact-item">
                                        <h6 style="color:#58D3F7 !important;">Números telefónicos</h6>
                                        <a href="https://wa.me/50494682260?text=¡Hola! Quisiera obtener más información sobre sus servicios." target="_blank" class="contact-item-info">+(504) 9468-2260</a><br>
                                        <a href="https://wa.me/50496403125?text=¡Hola! Quisiera obtener más información sobre sus servicios." target="_blank" class="contact-item-info">+(504) 9640-3125</a>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="contact-item">
                                        <h6 style="color:#58D3F7 !important;">Correo electrónico</h6>
                                        <!-- <p class="contact-item-info">info@mindsetgx.com</p> -->
                                        <a href="mailto:sales@mindsetgx.com">sales@mindsetgx.com</a>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="contact-item">
                                        <h6 style="color:#58D3F7 !important;">Dirección</h6>
                                        <p class="contact-item-info">San pedro sula, Honduras.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <img :src="`${rootPath}assets/images/Team/Contactus.svg`" alt="Contactus.svg">
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div class="container" v-else>
            <div class="header-wrap" style="padding: 0px !important;">
                
            </div>
        </div>

        <div class="hero-particles particle-container">
            <div class="particle particle-6"></div>
        </div>
    </header>
</template>


<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
    name: 'header', 
    setup(){
        const store = new useStore();
        // Obtener un valor computado desde el store
        let _contentHeader = computed(() => store.state.contentHeader);
        return { _contentHeader }
    },
    data(){
        return{
            rootPath: process.env.BASE_URL
        }
    }
}
</script>