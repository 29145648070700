<template>
    <div>
        <headerComponent></headerComponent>

        <router-view>

        </router-view>

        <footerComponent></footerComponent>
    </div>
</template>

<script>
//import components
import headerComponent from './header.vue'
import footerComponent from './footer.vue'

export default {
    name: 'router',
    components:{
        headerComponent,
        footerComponent
    }
}
</script>

<style>

</style>