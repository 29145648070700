//import vuex
import { createLogger, createStore } from 'vuex'

//define store
export const store = createStore({
    state () {
      return {
        count: 0,
        contentHeader: true
      }
    },
    mutations: {
        increment (state) {
          state.count++;
        },
        showContentHeader(state, pagevalue){
          state.contentHeader = pagevalue;
        },
        hideContentHeader(state){
          state.contentHeader = false;
        }
    },
    plugins: [createLogger()]
})