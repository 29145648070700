import { createApp } from 'vue'
import App from './components/views/layout/router.vue'
import { store } from './store'

const app = createApp(App)



app.use(store)

// import all componets
import Inicio from './components/views/pages/inicio.vue'

//import vue-router
import { createRouter, createWebHistory } from 'vue-router'

//define routes
const routes = [
    { path: '/', name: 'Inicio', component: Inicio },
    { path: '/Nosotros', name: 'Nosotros', component: () => import('./components/views/pages/nosotros.vue') },
    { path: '/Servicios', name: 'Servicios', component: () => import('./components/views/pages/servicios.vue') },
    { path: '/Contactanos', name: 'Contactanos', component: () => import('./components/views/pages/contactanos.vue') }
]
  
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})


app.use(router)
app.mount('#app')
